import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Rope Climbs`}</p>
    <p>{`then,`}</p>
    <p>{`2 Rounds for time of:`}</p>
    <p>{`1 Lap Run`}</p>
    <p>{`5 Rope Climbs to 15′`}</p>
    <p>{`15 Burpees`}</p>
    <p>{`25 V Ups`}</p>
    <p>{`50ft HS Walk `}<em parentName="p">{`(scaled to 100ft Bear Crawl)`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our next Mobility Class will be Sunday from 12:00-12:30.`}</em></strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`Reminder:  Evening CrossFit classes are now offered Monday-Friday
at 4:00, 5:00 & 6:00pm. and o**`}</strong>{`ur new Ville Bootcamp classes are
Tues-Friday at 9:30am.  The rest of this month the bootcamps are free
for non members so spread the word if you know of anyone that may want
to try it out. This class is included in your unlimited membership. ***`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      